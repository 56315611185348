import { RESET_TABLE_FILTER, SET_STUDENTS_TABLE_FILTER, SET_TUTORS_TABLE_FILTER, SET_RULES_TABLE_FILTER } from "./types";

export function setTutorsTableFilter(filter) {
	return {
		type : SET_TUTORS_TABLE_FILTER,
		filter
	};
}

export function setRulesTableFilter(filter) {
	return {
		type : SET_RULES_TABLE_FILTER,
		filter
	};
}


export function setUsersTableFilter(filter) {
	return {
		type : SET_STUDENTS_TABLE_FILTER,
		filter
	};
}

export function resetTableFilter() {
	return {
		type : RESET_TABLE_FILTER
	};
}