import React, { Component } from 'react';
import MDSpinner from 'react-md-spinner';

import Notification from '../../common/Notification';
import TextFieldGroup from '../../common/TextFieldGroup';

import { connect } from 'react-redux';
import { validateEditCategoryRule } from '../../common/validations/categoryRules';
import { ruleCategoryEditRule, ruleCategoryDeleteRule } from '../../../actions/rulesCategoryActions';

class EditCategoryRulesModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: false,
			notification: {},
			errors: {},
			_id: this.props._id,
			name: this.props.name,
			label: this.props.label,
		};
	}

	onChangeInput = (e) => {
		this.setState({
			[e.target.name]: e.target.value,
			errors: {
				...this.state.errors,
				[e.target.name]: null,
			},
		});
	};

	isValid = () => {
		const { errors, isValid } = validateEditCategoryRule(this.state);
		if (!isValid) {
			this.setState({ errors });
		}
		return isValid;
	};

	onSubmitDeleteRule = (e) => {
		e.preventDefault();
		this.setState({ isLoading: true, notification: {}, errors: {} });
		this.props.ruleCategoryDeleteRule({ id: this.state._id }).then((res) => {
			if (res.status !== 200) {
				this.setState({
					isLoading: false,
					notification: {
						isError: true,
						text: res.message,
					},
				});
			} else {
				this.setState({ isLoading: false });
				this.props.onCloseModal();
			}
		});
	};

	onSubmitEditRule = (e) => {
		e.preventDefault();
		this.setState({ isLoading: true, notification: {}, errors: {} });

		const { name, label, _id } = this.state;

		const dataToSubmit = {
			name,
			label,
			_id,
		};
		if (this.isValid()) {
			this.props.ruleCategoryEditRule(dataToSubmit).then((res) => {
				if (res.status !== 200) {
					this.setState({
						isLoading: false,
						notification: {
							isError: true,
							text: res.message,
						},
					});
				} else {
					this.setState({ isLoading: false });
					this.props.onCloseModal();
				}
			});
		} else {
			this.setState({
				isLoading: false,
				notification: {
					isError: true,
					text: 'Please complete the form above',
				},
			});
		}
	};

	render() {
		const { name, label, isLoading, errors, notification } = this.state;

		return (
			<div>
				<div
					className='modal modal-center fade show'
					id='modal-center'
					style={{ display: 'block' }}>
					<div className='modal-dialog'>
						<div
							className='modal-content'
							style={{ width: '692px' }}>
							<div className='modal-header'>
								<h4 className='modal-title text-center font-weight-bold'>
									Edit Category Rule
								</h4>
								<button
									onClick={this.props.onCloseModal}
									type='button'
									className='close'
									data-dismiss='modal'>
									<span aria-hidden='true'>
										<i className='ion-close-round fs-16 cursor-pointer'></i>
									</span>
								</button>
							</div>
							<div className='modal-body package-modal m-0 py-30 px-40'>
								<TextFieldGroup
									type='text'
									field='name'
									label='Slug'
									value={name}
									onChange={this.onChangeInput}
									disabled={isLoading}
									error={errors.name}
								/>
								<TextFieldGroup
									type='text'
									field='label'
									label='Label'
									value={label}
									onChange={this.onChangeInput}
									disabled={isLoading}
									error={errors.label}
								/>
								<Notification notification={notification} />
							</div>
							<div className='modal-footer p-20'>
								{isLoading ? (
									<div className='d-flex align-items-center justify-content-end'>
										<MDSpinner />
									</div>
								) : (
									<div className='d-flex align-items-center justify-content-end'>
										<button
											onClick={this.onSubmitEditRule}
											className='btn btn-main-orange btn-round letter-spacing-0 mr-2'>
											Save
										</button>
										<button
											onClick={this.props.onCloseModal}
											className='btn border-orange btn-round letter-spacing-0 mr-2'>
											Cancel
										</button>
										<button
											onClick={this.onSubmitDeleteRule}
											className='btn btn-main-red btn-round letter-spacing-0 mr-2'>
											Delete
										</button>
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
				<div className='modal-backdrop fade show'></div>
			</div>
		);
	}
}

export default connect(null, { ruleCategoryEditRule, ruleCategoryDeleteRule })(
	EditCategoryRulesModal
);
