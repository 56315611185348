import React from "react";

/*Import redux*/
import { connect } from "react-redux";

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentWillMount() {}
  render() {
    return (
			<div>
				<div className="card card-body">
					<div className="row justify-content-md-center">
						<div className="col-lg-8 col-md-12 text-center">
							<h2>Dashboard</h2>
						</div>
					</div>
				</div>
			</div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps, {})(Dashboard);
