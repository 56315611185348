import React, { Component } from 'react';
import MDSpinner from 'react-md-spinner';

import Notification from '../../common/Notification';
import TextAreaGroup from '../../common/TextAreaGroup';
import TextFieldGroup from '../../common/TextFieldGroup';
import ReactSelectFieldGroup from '../../common/ReactSelectFieldGroup';
import DateSelectGroup from '../../common/DateSelectGroup';
import MonthSelectGroup from '../../common/MonthSelectGroup';

import { connect } from 'react-redux';
import { validateEditRule } from '../../common/validations/rules';
import { ruleEditRule, ruleDeleteRule } from '../../../actions/rulesActions';
import { ruleCategoryGetRulesList } from '../../../actions/rulesCategoryActions';
// import category_options from '../../../data/rules_cat.json';
import type_options from '../../../data/rules_type.json';
import zipcode_type_options from '../../../data/rules_zipcode_type.json';
import date_type_options from '../../../data/rules_date_type.json';
import { isArray, isString } from 'lodash';

class EditRulesModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: false,
			notification: {},
			errors: {},
			_id: this.props._id,
			name: this.props.name,
			category: this.props.category,
			type: this.props.type,
			date_type: this.props.date_type,
			date_start: this.props.date_start,
			date_end: this.props.date_end,
			zipcode_type: this.props.zipcode_type,
			zipcodes: this.props.zipcodes,
			numberOfPerson: this.props.numberOfPerson,
			numberOfPersonAllPeriod: this.props.numberOfPersonAllPeriod,
			customer_message: this.props.customer_message,
			fieldLoop: {
				multiple_range_zipcode:
					this.props.zipcode_type === 'multiple_range' &&
					isArray(this.props.zipcodes)
						? this.props.zipcodes.length
						: 1,
			},
			category_options: [],
		};
	}

	componentDidMount = () => {
		this.props
			.ruleCategoryGetRulesList()
			.then((response) => {
				if (response.status !== 200) {
					this.setState({
						isLoading: false,
						notification: {
							text: 'Category rules failed to catch.',
							isError: true,
						},
					});
				} else {
					const getCategoryOpt = response.message.data.map((item) => ({
						...item,
						value: item.name,
					}));

					this.setState({
						isLoading: false,
						category_options: getCategoryOpt,
					});
				}
			})
			.catch((error) => {
				this.setState({
					isLoading: false,
					notification: {
						text: 'Category rules error to catch.',
						isError: true,
					},
				});
			});
	};

	setFieldLoop = (key = '', length = 1, name = '') => {
		if (length > 0 && key && name) {
			let currentFieldVal = this.state[name] || [];
			let currentFieldErr = this.state.errors[name] || [];

			if (!isArray(currentFieldVal)) {
				currentFieldVal = [currentFieldVal];
			}

			if (!isArray(currentFieldErr)) {
				currentFieldErr = [currentFieldErr];
			}

			if (currentFieldVal.length > length) {
				currentFieldVal.splice(length, currentFieldVal.length);
			}

			if (currentFieldErr.length > length) {
				currentFieldErr.splice(length, currentFieldErr.length);
			}

			Array(length)
				.fill()
				.map((_, index) => {
					if (!currentFieldVal[index]) {
						currentFieldVal[index] = '';
					}

					if (!currentFieldErr[index]) {
						currentFieldErr[index] = '';
					}
				});

			this.setState({
				[name]: currentFieldVal,
				fieldLoop: { ...this.state.fieldLoop, [key]: length },
				errors: {
					...this.state.errors,
					[name]: currentFieldErr,
				},
			});
		}
	};

	onChangeInput = (e) => {
		this.setState({
			[e.target.name]: e.target.value,
			errors: {
				...this.state.errors,
				[e.target.name]: null,
			},
		});
	};

	onChangeDateStartSelect = (value) => {
		this.setState({
			date_start: new Date(value).getTime(),
			errors: {
				date_start: null,
			},
		});
	};

	onChangeDateEndSelect = (value) => {
		this.setState({
			date_end: new Date(value).getTime(),
			errors: {
				date_end: null,
			},
		});
	};

	onChangeFieldLoop = (index = '', value = '', name = '') => {
		if (name) {
			const currentFieldVal = this.state[name] || [];
			const currentFieldError = this.state.errors[name];

			currentFieldVal[index] = value;

			if (currentFieldError && currentFieldError[index]) {
				currentFieldError[index] = null;
			}

			this.setState({
				[name]: currentFieldVal,
				errors: {
					...this.state.errors,
					[name]: currentFieldError,
				},
			});
		}
	};

	isValid = () => {
		const { errors, isValid } = validateEditRule(this.state);
		if (!isValid) {
			this.setState({ errors });
		}
		return isValid;
	};

	onSubmitDeleteRule = (e) => {
		e.preventDefault();
		this.setState({ isLoading: true, notification: {}, errors: {} });
		this.props.ruleDeleteRule({ id: this.state._id }).then((res) => {
			if (res.status !== 200) {
				this.setState({
					isLoading: false,
					notification: {
						isError: true,
						text: res.message,
					},
				});
			} else {
				this.setState({ isLoading: false });
				this.props.onCloseModal();
			}
		});
	};

	onSubmitEditRule = (e) => {
		e.preventDefault();
		this.setState({ isLoading: true, notification: {}, errors: {} });

		const {
			name,
			category,
			type,
			date_type,
			date_start,
			date_end,
			zipcode_type,
			zipcodes,
			numberOfPerson,
			numberOfPersonAllPeriod,
			customer_message,
			_id,
		} = this.state;

		const dataToSubmit = {
			name,
			category,
			type,
			date_type,
			date_start: new Date(date_start).toDateString(),
			date_end: new Date(date_end).toDateString(),
			zipcode_type,
			zipcodes,
			numberOfPerson: Number(numberOfPerson),
			numberOfPersonAllPeriod: Number(numberOfPersonAllPeriod),
			customer_message,
			_id,
		};
		if (this.isValid()) {
			this.props.ruleEditRule(dataToSubmit).then((res) => {
				if (res.status !== 200) {
					this.setState({
						isLoading: false,
						notification: {
							isError: true,
							text: res.message,
						},
					});
				} else {
					this.setState({ isLoading: false });
					this.props.onCloseModal();
				}
			});
		} else {
			this.setState({
				isLoading: false,
				notification: {
					isError: true,
					text: 'Please complete the form above',
				},
			});
		}
	};

	render() {
		const {
			name,
			category,
			type,
			date_type,
			date_start,
			date_end,
			zipcode_type,
			zipcodes,
			numberOfPerson,
			numberOfPersonAllPeriod,
			customer_message,
			isLoading,
			errors,
			notification,
			fieldLoop,
			category_options
		} = this.state;

		return (
			<div>
				<div
					className='modal modal-center fade show'
					id='modal-center'
					style={{ display: 'block' }}>
					<div className='modal-dialog'>
						<div
							className='modal-content'
							style={{ width: '692px' }}>
							<div className='modal-header'>
								<h4 className='modal-title text-center font-weight-bold'>
									Edit Rule
								</h4>
								<button
									onClick={this.props.onCloseModal}
									type='button'
									className='close'
									data-dismiss='modal'>
									<span aria-hidden='true'>
										<i className='ion-close-round fs-16 cursor-pointer'></i>
									</span>
								</button>
							</div>
							<div className='modal-body package-modal m-0 py-30 px-40'>
								<TextFieldGroup
									type='text'
									field='name'
									label='Name'
									value={name}
									onChange={this.onChangeInput}
									disabled={isLoading}
									error={errors.name}
								/>
								<ReactSelectFieldGroup
									label='Category'
									value={
										isArray(category)
											? category.map((itemCat) => {
													return category_options.find(
														(item) =>
															item.value ===
															itemCat
													);
											  })
											: category_options.find(
													(item) =>
														item.value === category
											  )
									}
									field='category'
									options={category_options}
									onChange={(val) => {
										this.setState({
											category: val.map(
												(item) => item.value
											),
										});
									}}
									isMulti={true}
									isDisabled={isLoading}
									isSearchable={false}
									isClearable={false}
									error={errors.category}
								/>
								<ReactSelectFieldGroup
									label='Type'
									value={type_options.find(
										(item) => item.value === type
									)}
									field='type'
									options={type_options}
									onChange={(val) => {
										this.setState({
											type: val.value,
										});
									}}
									isDisabled={isLoading}
									isSearchable={false}
									isClearable={false}
									error={errors.type}
								/>

								{type === 'date_zipcodes' ||
								type === 'people' ? (
									<>
										<ReactSelectFieldGroup
											label='Zipcode type'
											field='zipcode_type'
											value={zipcode_type_options.find(
												(item) =>
													item.value === zipcode_type
											)}
											options={zipcode_type_options}
											onChange={(val) => {
												this.setState({
													zipcode_type: val.value,
												});
											}}
											isDisabled={isLoading}
											isSearchable={false}
											isClearable={false}
											error={errors.zipcode_type}>
											{zipcode_type === 'range' ||
											zipcode_type ===
												'multiple_range' ? (
												<div className='badge bg-light mt-2 p-2'>
													Example: 0000-0000
												</div>
											) : zipcode_type === 'specific' ? (
												<div className='badge bg-light mt-2 p-2'>
													Example: 0000
												</div>
											) : zipcode_type === 'multiple' ? (
												<div className='badge bg-light mt-2 p-2'>
													Example: 0000,0000
												</div>
											) : (
												''
											)}
										</ReactSelectFieldGroup>
										{zipcode_type === 'multiple_range' ? (
											<div className='field-multiple'>
												<div
													className='row'
													style={{
														justifyContent:
															'space-between',
														width: 60,
														marginLeft: 'auto',
														marginBottom: 10,
													}}>
													<button
														className={`btn btn-circle ${
															fieldLoop.multiple_range_zipcode <=
															1
																? 'disabled'
																: ''
														}`}
														onClick={() => {
															this.setFieldLoop(
																'multiple_range_zipcode',
																fieldLoop.multiple_range_zipcode -
																	1,
																'zipcodes'
															);
														}}>
														<i className='fa fa-sharp fa-solid fa-minus text-danger'></i>
													</button>
													<button
														className='btn btn-circle'
														onClick={() => {
															this.setFieldLoop(
																'multiple_range_zipcode',
																fieldLoop.multiple_range_zipcode +
																	1,
																'zipcodes'
															);
														}}>
														<i className='fa fa-sharp fa-solid fa-plus text-info'></i>
													</button>
												</div>
												{Array(
													fieldLoop.multiple_range_zipcode
												)
													.fill()
													.map((_, index) => (
														<TextFieldGroup
															key={`multiple_range_zipcode_${index}`}
															field={`zipcodes_${index}`}
															label={`Zipcodes ${
																index + 1
															}`}
															value={
																isArray(
																	zipcodes
																)
																	? zipcodes[
																			index
																	  ]
																	: index ===
																	  0
																	? zipcodes
																	: ''
															}
															onChange={(e) => {
																this.onChangeFieldLoop(
																	index,
																	e.target
																		.value,
																	'zipcodes'
																);
															}}
															disabled={isLoading}
															error={
																isArray(
																	errors.zipcodes
																)
																	? errors
																			.zipcodes[
																			index
																	  ]
																	: errors.zipcodes
															}
														/>
													))}
											</div>
										) : (
											<TextFieldGroup
												field='zipcodes'
												label='Zipcodes'
												value={zipcodes}
												onChange={this.onChangeInput}
												disabled={isLoading}
												error={errors.zipcodes}
											/>
										)}
									</>
								) : null}
								{type === 'date' ||
								type === 'date_zipcodes' ||
								type === 'people' ? (
									<>
										<ReactSelectFieldGroup
											label='Date Type'
											value={date_type_options.find(
												(item) =>
													item.value === date_type
											)}
											field='type'
											options={date_type_options}
											onChange={(val) => {
												this.setState({
													date_type: val.value,
												});
											}}
											isDisabled={isLoading}
											isSearchable={false}
											isClearable={false}
											error={errors.date_type}
										/>
										{date_type === 'dd-MM' ? (
											<>
												<MonthSelectGroup
													name='date_start'
													label='Start date'
													value={date_start}
													maxDate={date_end}
													onChange={
														this
															.onChangeDateStartSelect
													}
													isDisabled={isLoading}
													error={errors.date_start}
												/>
												<MonthSelectGroup
													name='date_end'
													label='End date'
													value={date_end}
													minDate={date_start}
													onChange={
														this
															.onChangeDateEndSelect
													}
													isDisabled={isLoading}
													error={errors.date_end}
												/>
											</>
										) : (
											<>
												<DateSelectGroup
													name='date_start'
													label='Start date'
													value={date_start}
													maxDate={date_end}
													onChange={
														this
															.onChangeDateStartSelect
													}
													isDisabled={isLoading}
													error={errors.date_start}
												/>
												<DateSelectGroup
													name='date_end'
													label='End date'
													value={date_end}
													minDate={date_start}
													onChange={
														this
															.onChangeDateEndSelect
													}
													isDisabled={isLoading}
													error={errors.date_end}
												/>
											</>
										)}
									</>
								) : null}
								{type === 'people' ? (
									<>
										<TextFieldGroup
											type='number'
											field='numberOfPerson'
											label='Number Of Person'
											value={numberOfPerson}
											onChange={this.onChangeInput}
											disabled={isLoading}
											error={errors.numberOfPerson}
										/>
										<TextFieldGroup
											type='number'
											field='numberOfPersonAllPeriod'
											label='Number Of Person Rest of Period'
											value={numberOfPersonAllPeriod}
											onChange={this.onChangeInput}
											disabled={isLoading}
											error={
												errors.numberOfPersonAllPeriod
											}
										/>
									</>
								) : null}
								<TextAreaGroup
									field='customer_message'
									label='Message to customer'
									value={customer_message}
									placeholder='Type something'
									onChange={this.onChangeInput}
									disabled={isLoading}
									error={errors.customer_message}
								/>
								<Notification notification={notification} />
							</div>
							<div className='modal-footer p-20'>
								{isLoading ? (
									<div className='d-flex align-items-center justify-content-end'>
										<MDSpinner />
									</div>
								) : (
									<div className='d-flex align-items-center justify-content-end'>
										<button
											onClick={this.onSubmitEditRule}
											className='btn btn-main-orange btn-round letter-spacing-0 mr-2'>
											Save
										</button>
										<button
											onClick={this.props.onCloseModal}
											className='btn border-orange btn-round letter-spacing-0 mr-2'>
											Cancel
										</button>
										<button
											onClick={this.onSubmitDeleteRule}
											className='btn btn-main-red btn-round letter-spacing-0 mr-2'>
											Delete
										</button>
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
				<div className='modal-backdrop fade show'></div>
			</div>
		);
	}
}

export default connect(null, {
	ruleEditRule,
	ruleDeleteRule,
	ruleCategoryGetRulesList,
})(EditRulesModal);
