import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

const AdminRoute = ({ component: Component, auth, ...rest }) => (
  <Route
    {...rest}
    render = { props =>
      auth.isAuthenticated === true && ( auth.user.type === "admin" || auth.user.type === "validator" ) ? (
        <Component {...props} />
      ) : (
        <Redirect to="/login" />
      )
    }
  />
);

function mapStateToProps(state) {
    return {
        auth: state.auth
    };
}

export default connect(mapStateToProps)(AdminRoute);