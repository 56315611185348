import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ReactTable from 'react-table';
import { matchSorter } from 'match-sorter';

import Notification from '../../common/Notification';

import { connect } from 'react-redux';
import { ruleGetRulesList } from '../../../actions/rulesActions';
import { ruleCategoryGetRulesList } from '../../../actions/rulesCategoryActions';
import { setRulesTableFilter } from '../../../actions/tableFilterActions';
import AddRulesModal from './addRulesModal';
import EditRulesModal from './editRulesModal';
// import category_options from '../../../data/rules_cat.json';
import type_options from '../../../data/rules_type.json';
import { isArray, isEmpty } from 'lodash';

class RulesTable extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: [],
			pages: 1,
			isLoading: false,
			selected: {},
			selectedRule: {},
			sync: false,
			notification: {},
			errors: {},
			isEditModalOpen: false,
			isAddModalOpen: false,
			category_options: [],
		};
		this.table = React.createRef();
	}

	shouldComponentUpdate = (nextProps, nextState) => {
		if (this.props.modal.sync) {
			if (nextProps.modal.sync !== this.props.modal.sync) {
				this.getRulesList(this.table.current.state);
			}
			return true;
		}
		return true;
	};

	componentWillMount = () => {
		window.scroll({
			top: 0,
			left: 0,
			behavior: 'smooth',
		});
	};

	componentWillUnmount = () => {
		this.props.setRulesTableFilter(this.state.table_filter);
	};

	componentDidMount = () => {
		this.props
			.ruleCategoryGetRulesList()
			.then((response) => {
				if (response.status !== 200) {
					this.setState({
						isLoading: false,
						notification: {
							text: 'Category rules failed to catch.',
							isError: true,
						},
					});
				} else {
					const getCategoryOpt = response.message.data.map(
						(item) => ({
							...item,
							value: item.name,
						})
					);

					this.setState({
						isLoading: false,
						category_options: getCategoryOpt,
					});
				}
			})
			.catch((error) => {
				this.setState({
					isLoading: false,
					notification: {
						text: 'Category rules error to catch.',
						isError: true,
					},
				});
			});
	};

	openEditRuleHandler = (data) => {
		this.setState({
			isEditModalOpen: true,
			selectedRule: data,
		});
	};

	closeEditRuleHandler = () => {
		this.setState({
			isEditModalOpen: false,
			selectedRule: {},
		});
		this.getRulesList(this.table.current.state);
	};

	openAddUserHandler = () => {
		this.setState({
			isAddModalOpen: true,
		});
	};

	closeAddUserHandler = () => {
		this.setState({
			isAddModalOpen: false,
		});
		this.getRulesList(this.table.current.state);
	};

	getRulesList = (state, instance) => {
		this.setState({
			isLoading: true,
			table_filter: state.filtered,
			notification: {},
		});
		// const fromDate = moment(this.state.from_date).subtract("days", 1);
		// const toDate = moment(this.state.to_date).add("days", 1);
		this.props
			.ruleGetRulesList({
				pagination: {
					pageSize: state.pageSize,
					page: state.page,
				},
				sorted: state.sorted,
				filtered: state.filtered,
			})
			.then((response) => {
				if (response.status !== 200) {
					this.setState({
						isLoading: false,
						notification: {
							text: 'Some features has been updated, please logout and login again',
							isError: true,
						},
					});
				} else {
					this.setState({
						isLoading: false,
						data: response.message.data,
						loading: false,
						pages: response.message.totalPage,
						totalStudents: response.message.totalStudents,
					});
				}
			})
			.catch((error) => {
				this.setState({ isLoading: false });
			});
	};

	render() {
		const { match } = this.props;
		const {
			data,
			pages,
			isLoading,
			notification,
			selectedRule,
			isEditModalOpen,
			isAddModalOpen,
			category_options,
		} = this.state;

		return (
			<div>
				<div className='card mx-15'>
					<div className='card-header flex-column justify-content-center'>
						<h1 className='mb-0'>Rules</h1>
						<div
							role='button'
							id='muted-text-group'
							style={{ right: 34 }}
							onClick={() => this.openAddUserHandler()}
							className='position-absolute d-flex align-items-center justify-content-center cursor-pointer font-weight-bold btn-info btn-round py-10 px-20'>
							<i className='ion-plus-round cursor-pointer mr-2'></i>
							Add rule
						</div>
					</div>
					<div className='main-view'>
						<Notification notification={notification} />
						<ReactTable
							filterable
							defaultFiltered={
								this.props.studentsTableFilter
									? this.props.studentsTableFilter
									: []
							}
							defaultFilterMethod={(filter, row) =>
								String(row[filter.id]) === filter.value
							}
							columns={[
								{
									Header: 'Name',
									accessor: 'name',
									sortable: false,
									filterMethod: (filter, rows) =>
										matchSorter(rows, filter.value, {
											keys: ['name'],
										}),
									filterAll: true,
									Cell: (props) => props.row.name,
									style: { textAlign: 'center' },
								},
								{
									Header: 'Category',
									accessor: 'category',
									sortable: false,
									filterMethod: (filter, rows) =>
										matchSorter(rows, filter.value, {
											keys: ['category'],
										}),
									Cell: (props) => {
										let cat_rule = [];

										if (
											isArray(category_options) &&
											!isEmpty(category_options)
										) {
											if (isArray(props.row.category)) {
												props.row.category.map(
													(itemRule) => {
														const labelRule =
															category_options.find(
																(item) =>
																	item.value ===
																	itemRule
															);

														if (labelRule) {
															cat_rule.push(
																labelRule.label
															);
														}
													}
												);

												return cat_rule.join(', ');
											} else {
												cat_rule =
													category_options.find(
														(item) =>
															item.value ===
															props.row.category
													).label;
											}
										}

										return cat_rule;
									},
									filterAll: true,
									style: { textAlign: 'center' },
								},
								{
									Header: 'Type',
									accessor: 'type',
									sortable: false,
									filterMethod: (filter, rows) =>
										matchSorter(rows, filter.value, {
											keys: ['type'],
										}),
									filterAll: true,
									Cell: (props) => {
										const type_rule = type_options.find(
											(item) =>
												item.value === props.row.type
										);

										return type_rule.label;
									},
									style: { textAlign: 'center' },
								},
								{
									Header: 'Date start',
									accessor: 'date_start',
									sortable: false,
									filterAll: true,
									filterable: true,
									filterMethod: (filter, rows) =>
										matchSorter(rows, filter.value, {
											keys: ['date_start'],
										}),
									Cell: (props) => {
										const dateType =
											props.row._original.date_type;

										const formatDate = {
											day: '2-digit',
											month: '2-digit',
										};

										if (dateType === 'dd-MM-YYYY') {
											formatDate['year'] = 'numeric';
										}

										return (
											<span>
												{new Date(
													props.row.date_start
												).toLocaleDateString(
													'id-ID',
													formatDate
												)}
											</span>
										);
									},
									style: {
										textAlign: 'center',
									},
								},
								{
									Header: 'Date end',
									accessor: 'date_end',
									sortable: false,
									filterAll: true,
									filterable: true,
									filterMethod: (filter, rows) =>
										matchSorter(rows, filter.value, {
											keys: ['date_end'],
										}),
									Cell: (props) => {
										const dateType =
											props.row._original.date_type;

										const formatDate = {
											day: '2-digit',
											month: '2-digit',
										};

										if (dateType === 'dd-MM-YYYY') {
											formatDate['year'] = 'numeric';
										}

										return (
											<span>
												{new Date(
													props.row.date_end
												).toLocaleDateString(
													'id-ID',
													formatDate
												)}
											</span>
										);
									},
									style: {
										textAlign: 'center',
									},
								},
								{
									Header: 'Zipcodes',
									accessor: 'zipcodes',
									sortable: false,
									filterAll: true,
									filterable: true,
									filterMethod: (filter, rows) =>
										matchSorter(rows, filter.value, {
											keys: ['zipcodes'],
										}),
									Cell: (props) => {
										return (
											<span>
												{(props.row.type ===
													'date_zipcodes' ||
													props.row.type ===
														'people') &&
												props.row.zipcodes ? (
													<>
														{isArray(
															props.row.zipcodes
														)
															? props.row.zipcodes.join(
																	', '
															  )
															: props.row
																	.zipcodes}
													</>
												) : (
													'-'
												)}
											</span>
										);
									},
									style: {
										textAlign: 'center',
									},
								},
								{
									Header: 'Number Of Person',
									accessor: 'numberOfPerson',
									sortable: false,
									filterAll: true,
									filterable: true,
									filterMethod: (filter, rows) =>
										matchSorter(rows, filter.value, {
											keys: ['numberOfPerson'],
										}),
									Cell: (props) => {
										return (
											<span>
												{props.row.type === 'people' &&
												props.row.numberOfPerson
													? props.row.numberOfPerson
													: 0}
											</span>
										);
									},
									style: {
										textAlign: 'center',
									},
								},
								{
									Header: 'Number Of Person Rest of Period',
									accessor: 'numberOfPersonAllPeriod',
									sortable: false,
									filterAll: true,
									filterable: true,
									filterMethod: (filter, rows) =>
										matchSorter(rows, filter.value, {
											keys: ['numberOfPersonAllPeriod'],
										}),
									Cell: (props) => {
										return (
											<span>
												{props.row.type === 'people' &&
												props.row
													.numberOfPersonAllPeriod
													? props.row
															.numberOfPersonAllPeriod
													: 0}
											</span>
										);
									},
									style: {
										textAlign: 'center',
									},
								},
								{
									Header: 'Notification',
									accessor: 'customer_message',
									sortable: false,
									filterAll: true,
									filterable: true,
									filterMethod: (filter, rows) =>
										matchSorter(rows, filter.value, {
											keys: ['notification'],
										}),
									Cell: (props) => {
										return (
											<span>
												{props.row.customer_message}
											</span>
										);
									},
									style: {
										textAlign: 'center',
									},
								},
								{
									Header: '',
									accessor: '_id',
									sortable: false,
									filterable: false,
									Cell: (row) => (
										<span>
											<button
												onClick={() =>
													this.openEditRuleHandler(
														row.original
													)
												}
												className='btn-round btn-main-orange border-0 cursor-pointer px-3 py-1'>
												Edit
											</button>
										</span>
									),
									style: { textAlign: 'center' },
								},
							]}
							ref={this.table}
							data={data} // should default to []
							pages={pages} // should default to -1 (which means we don't know how many pages we have)
							loading={isLoading}
							manual // informs React Table that you'll be handling sorting and pagination server-side
							onFetchData={this.getRulesList}
							defaultPageSize={25}
							showPagination={true}
							className='-striped -highlight'
						/>
					</div>
				</div>
				{isEditModalOpen ? (
					<EditRulesModal
						name={selectedRule.name}
						category={selectedRule.category}
						type={selectedRule.type}
						date_type={selectedRule.date_type}
						date_start={selectedRule.date_start}
						date_end={selectedRule.date_end}
						zipcode_type={selectedRule.zipcode_type}
						zipcodes={selectedRule.zipcodes}
						numberOfPerson={selectedRule.numberOfPerson}
						numberOfPersonAllPeriod={
							selectedRule.numberOfPersonAllPeriod
						}
						customer_message={selectedRule.customer_message}
						_id={selectedRule._id}
						onCloseModal={this.closeEditRuleHandler}
					/>
				) : null}
				{isAddModalOpen ? (
					<AddRulesModal onCloseModal={this.closeAddUserHandler} />
				) : null}
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		auth: state.auth,
		modal: state.modal,
		tutorsTableFilter: state.tableFilter.tutors,
	};
};

export default connect(mapStateToProps, {
	ruleGetRulesList,
	ruleCategoryGetRulesList,
	setRulesTableFilter,
})(RulesTable);
