import React from 'react';
import ReactInputMask from 'react-input-mask';
import DatePicker from 'react-datepicker';
import classnames from 'classnames';

const MonthSelectGroup = ({
	minDate,
	maxDate,
	value,
	label,
	error,
	onChange,
	placeholderText,
	type,
	name,
	isDisabled,
}) => {
	return (
		<div className={classnames('form-group row', { 'has-error': error })}>
			{label ? (
				<label className='col-form-label col-md-3'>
					{label}
					<span className='fs-12 pl-2 font-italic text-gray'>
						{type === 'input-mask' ? '( MM-DD )' : null}
					</span>
				</label>
			) : null}
			<div className='col-form-label px-0 col-md-1 d-flex align-items-start justify-content-center'>
				<p className='mb-0'>:</p>
			</div>
			<div className='col-md-8 pl-0'>
				<div className='input-group'>
					<div className='flex-grow-2'>
						{type === 'input-mask' ? (
							<ReactInputMask
								value={value}
								mask='99-99'
								name={name}
								className={
									error
										? 'form-control react-datepicker-style border-danger px-20'
										: 'form-control react-datepicker-style px-20'
								}
								onChange={onChange}
								disabled={isDisabled}
								placeholder='MM-DD'
							/>
						) : (
							<DatePicker
								selected={value}
								dateFormat='dd-MM'
								onChange={onChange}
								className={
									error
										? 'form-control react-datepicker-style px-20 border-danger'
										: 'form-control react-datepicker-style px-20'
								}
								placeholderText='DD-MM'
								disabled={isDisabled}
								minDate={minDate ? minDate : null}
								maxDate={maxDate ? maxDate : null}
								fixedHeight={true}
								scrollableYearDropdown={false}
								showYearDropdown={false}
								showMonthDropdown={false}
								popperPlacement='bottom-start'
								renderCustomHeader={({
									monthDate,
									customHeaderCount,
									decreaseMonth,
									increaseMonth,
								}) => (
									<div>
										<button
											aria-label='Previous Month'
											className={
												'react-datepicker__navigation react-datepicker__navigation--previous'
											}
											onClick={decreaseMonth}>
											<span
												className={
													'react-datepicker__navigation-icon react-datepicker__navigation-icon--previous'
												}>
												{'<'}
											</span>
										</button>
										<span className='react-datepicker__current-month'>
											{monthDate.toLocaleString('en-US', {
												month: 'long',
											})}
										</span>
										<button
											aria-label='Next Month'
											className={
												'react-datepicker__navigation react-datepicker__navigation--next'
											}
											onClick={increaseMonth}>
											<span
												className={
													'react-datepicker__navigation-icon react-datepicker__navigation-icon--next'
												}>
												{'>'}
											</span>
										</button>
									</div>
								)}
							/>
						)}
					</div>
					<i
						className='fa fa-calendar-o absolute-center'
						style={{
							position: 'absolute',
							top: '28%',
							right: 20,
						}}></i>
				</div>
				{error && <div className='form-control-feedback'>{error}</div>}
			</div>
		</div>
	);
};

export default MonthSelectGroup;
