import { API } from '../utils/apiConnect';

export function userGetUsersList(data) {
	return dispatch => {
		return API.post('/users/list', data)
		.then( res => {
			return res.data;
		});
	}
}

export function userAddUser(data) {
	return dispatch => {
		return API.post('/users/add', data)
		.then( res => {
			return res.data;
		});
	}
}

export function userEditUser(data) {
	return dispatch => {
		return API.post('/users/edit', data)
		.then( res => {
			return res.data;
		});
	}
}

export function userGetSingleUser(data) {
	return dispatch => {
		return API.post('/users/single', data)
		.then( res => {
			return res.data;
		});
	}
}

export function userDeleteUser(data) {
	return dispatch => {
		return API.post('/users/delete', data)
		.then( res => {
			return res.data;
		});
	}
}
