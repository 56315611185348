import React, { Component } from 'react';
import MDSpinner from 'react-md-spinner';

import Notification from '../../common/Notification';
import TextAreaGroup from '../../common/TextAreaGroup';
import TextFieldGroup from '../../common/TextFieldGroup';
import ReactSelectFieldGroup from '../../common/ReactSelectFieldGroup';

import { connect } from 'react-redux';
import { validateInputUser } from '../../common/validations/users';
import { userAddUser } from '../../../actions/usersActions';

class AddUsersModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: false,
			notification: {},
			errors: {},
			first_name: '',
			last_name: '',
			phone: '',
			email: '',
			type: '',
			is_active: true,
		};
	}

	onChangeInput = (e) => {
		this.setState({
			[e.target.name]: e.target.value,
			errors: {
				...this.state.errors,
				[e.target.name]: null,
			},
		});
	};

	onChangeSelect = (val) => {
		this.setState({
			type: val.value,
		});

		console.log('val.value', val.value);
	};

	isValid = () => {
		const { errors, isValid } = validateInputUser(this.state);
		if (!isValid) {
			this.setState({ errors });
		}
		return isValid;
	};

	onSubmitAddUser = (e) => {
		e.preventDefault();
		this.setState({ isLoading: true, notification: {}, errors: {} });

		const { first_name, last_name, email, phone, type, is_active } =
			this.state;

		const dataToSubmit = {
			first_name,
			last_name,
			email,
			phone,
			type,
			is_active,
		};

		if (this.isValid()) {
			this.props.userAddUser(dataToSubmit).then((res) => {
				if (res.status !== 200) {
					this.setState({
						isLoading: false,
						notification: {
							isError: true,
							text: res.message,
						},
					});
				} else {
					this.setState({ isLoading: false });
					this.props.onCloseModal();
				}
			});
		} else {
			this.setState({
				isLoading: false,
				notification: {
					isError: true,
					text: 'Please complete the form above',
				},
			});
		}
	};

	render() {
		const {
			first_name,
			last_name,
			email,
			phone,
			type,
			isLoading,
			errors,
			notification,
		} = this.state;

		const type_options = [
			{ value: 'admin', label: 'Admin' },
			{ value: 'user', label: 'User' },
		];

		return (
			<div>
				<div
					className='modal modal-center fade show'
					id='modal-center'
					style={{ display: 'block' }}>
					<div className='modal-dialog'>
						<div
							className='modal-content'
							style={{ width: '692px' }}>
							<div className='modal-header'>
								<h4 className='modal-title text-center font-weight-bold'>
									Add User
								</h4>
								<button
									onClick={this.props.onCloseModal}
									type='button'
									className='close'
									data-dismiss='modal'>
									<span aria-hidden='true'>
										<i className='ion-close-round fs-16 cursor-pointer'></i>
									</span>
								</button>
							</div>
							<div className='modal-body package-modal m-0 py-30 px-40'>
								<ReactSelectFieldGroup
									label='Type'
									field='type'
									options={type_options}
									onChange={this.onChangeSelect}
									isDisabled={isLoading}
									isSearchable={false}
									isClearable={false}
									error={errors.type}
								/>
								<TextFieldGroup
									field='first_name'
									label='First name'
									value={first_name}
									placeholder='First name'
									onChange={this.onChangeInput}
									disabled={isLoading}
									error={errors.first_name}
								/>
								<TextFieldGroup
									field='last_name'
									label='Last Name'
									value={last_name}
									placeholder='Last name'
									onChange={this.onChangeInput}
									disabled={isLoading}
									error={errors.last_name}
								/>
								<TextFieldGroup
									field='email'
									label='Email'
									value={email}
									placeholder='Type something'
									onChange={this.onChangeInput}
									disabled={isLoading}
									error={errors.email}
								/>
								<TextFieldGroup
									type='number'
									field='phone'
									label='Phone number'
									value={phone}
									placeholder='Type something'
									onChange={this.onChangeInput}
									disabled={isLoading}
									error={errors.phone}
								/>
								<Notification notification={notification} />
							</div>
							<div className='modal-footer p-20'>
								{isLoading ? (
									<div className='d-flex align-items-center justify-content-end'>
										<MDSpinner />
									</div>
								) : (
									<div className='d-flex align-items-center justify-content-end'>
										<button
											onClick={this.onSubmitAddUser}
											className='btn btn-main-orange btn-round letter-spacing-0 mr-2'>
											Add
										</button>
										<button
											onClick={this.props.onCloseModal}
											className='btn border-orange btn-round letter-spacing-0'>
											Cancel
										</button>
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
				<div className='modal-backdrop fade show'></div>
			</div>
		);
	}
}

export default connect(null, { userAddUser })(AddUsersModal);
