import { API } from '../utils/apiConnect';

export function ruleGetRulesList(data) {
	return dispatch => {
		return API.post('/rules/list', data)
		.then( res => {
			return res.data;
		});
	}
}

export function ruleAddRule(data) {
	return dispatch => {
		return API.post('/rules/add', data)
		.then( res => {
			return res.data;
		});
	}
}

export function ruleEditRule(data) {
	return dispatch => {
		return API.post('/rules/edit', data)
		.then( res => {
			return res.data;
		});
	}
}

export function ruleGetSingleRule(data) {
	return dispatch => {
		return API.post('/rules/single', data)
		.then( res => {
			return res.data;
		});
	}
}

export function ruleDeleteRule(data) {
	return dispatch => {
		return API.post('/rules/delete', data)
		.then( res => {
			return res.data;
		});
	}
}
