import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

const TextFieldGroup = ({ field, value, id, label, error, type, onChange, disabled, icon, placeholder }) => {
	return (
		<div className={classnames("form-group row", {'has-error has-danger': error})}>
			<label className="col-form-label col-md-3">{label}</label>
			<div className="col-form-label px-0 col-md-1 d-flex align-items-center justify-content-center">
				<p className='mb-0'>:</p>
			</div>
			{ !isEmpty(type) ? 
				<div className="col-md-8 pl-0">
					<div className='d-flex align-items-center'>
						{ type === 'price' ? 
							<p className='mb-0 font-weight-bold mr-4'>&euro;</p>
						: null }
						<input
							value = {value}
							onChange = {onChange}
							type={type}
							name={field}
							placeholder = {placeholder}
							className={type === 'hours' || type === 'credits' ? "form-control w-100px" : "form-control"}
							disabled={disabled}
							id={id} 
						/>
						{ type === 'hours' ? 
							<p className='mb-0 font-weight-bold mx-3'>hours</p>
						: null }
						{ type === 'credits' ? 
							<p className='mb-0 font-weight-bold mx-3'>Ideaal Catering credits</p>
						: null }
					</div>
					{error && <div className="form-control-feedback text-left">{error}</div>}
				</div>
				: <div className="col-md-8 pl-0">
					<input
						value = {value}
						onChange = {onChange}
						type={type}
						name={field}
						placeholder = {placeholder}
						className="form-control"
						disabled={disabled}
						id={id} 
					/>
					{error && <div className="form-control-feedback text-left">{error}</div>}
				</div>
			}
			{icon && <div className={icon}></div>}

		</div>
	);
}

TextFieldGroup.propTypes = {
	field: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	error: PropTypes.string,
	type: PropTypes.string,
}


TextFieldGroup.defaultProps = {
	type: 'text'
}


export default TextFieldGroup;