import React, { Component } from 'react';
import { Link } from "react-router-dom";
import ReactTable from "react-table";
import { matchSorter } from "match-sorter";

import Notification from '../../common/Notification';

import { connect } from 'react-redux';
import { userGetUsersList, userDeleteUser } from '../../../actions/usersActions';
import { setUsersTableFilter } from '../../../actions/tableFilterActions';
import AddUsersModal from "./addUsersModal";
import EditUsersModal from "./editUsersModal";

class UsersTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      pages: 1,
      isLoading: false,
      selected: {},
      selectedUser: {},
      sync: false,
      notification: {},
      errors: {},
      isEditModalOpen: false,
      isAddModalOpen: false,
    };
    this.table = React.createRef();
  }

  shouldComponentUpdate = (nextProps, nextState) => {
    if (this.props.modal.sync) {
      if (nextProps.modal.sync !== this.props.modal.sync) {
        this.getStudentsList(this.table.current.state);
      }
      return true;
    }
    return true;
  }

  // componentWillUnmount() {
  //   this.props.setSalesTableFilter(this.state.table_filter);
  // }

  componentWillMount = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  };

  componentWillUnmount = () => {
    this.props.setUsersTableFilter(this.state.table_filter);
  };

  openEditUserHandler = (data) => {
    console.log(data);
    this.setState({ 
      isEditModalOpen : true,
      selectedUser: data
    });
  };

  closeEditUserHandler = () => {
    this.setState({
      isEditModalOpen: false,
      selectedUser: {},
    });
    this.getStudentsList(this.table.current.state);
  };

  openAddUserHandler = () => {
    this.setState({ 
      isAddModalOpen : true
    });
  };
  
  closeAddUserHandler = () => {
    this.setState({
      isAddModalOpen: false,
    });
    this.getStudentsList(this.table.current.state);
  }

  getStudentsList = (state, instance) => {
    this.setState({ isLoading: true, table_filter: state.filtered, notification: {} });
    // const fromDate = moment(this.state.from_date).subtract("days", 1);
    // const toDate = moment(this.state.to_date).add("days", 1);
    this.props.userGetUsersList({
      pagination: {
        pageSize: state.pageSize,
        page: state.page,
      },
      sorted: state.sorted,
      filtered: state.filtered,
    }).then((response) => {
      if (response.status !== 200) {
        this.setState({
          isLoading: false,
          notification: {
            text: "Some features has been updated, please logout and login again",
            isError: true,
          },
        });
      } else {
        this.setState({
          isLoading: false,
          data: response.message.data,
          loading: false,
          pages: response.message.totalPage,
          totalStudents: response.message.totalStudents,
        });
      }
    }).catch((error) => {
      this.setState({ isLoading: false });
    });
  };

  render() {
    const { match } = this.props;
    const {
      data,
      pages,
      isLoading,
      notification,
      selectedUser,
      isEditModalOpen,
      isAddModalOpen
    } = this.state;

    return (
      <div>
        <div className='card mx-15'>
          <div className='card-header flex-column justify-content-center'>
            <h1 className='mb-0'>
              Users
            </h1>
            <div 
              role='button' 
              id='muted-text-group' 
              style={{right: 34}}
              onClick={() => this.openAddUserHandler()} 
              className='position-absolute d-flex align-items-center justify-content-center cursor-pointer font-weight-bold btn-info btn-round py-10 px-20' 
            >
              <i className='ion-plus-round cursor-pointer mr-2'></i>
              Add users
            </div>
          </div>
          <div className="main-view">
            <Notification notification={notification} />
            <ReactTable
              filterable
              defaultFiltered={this.props.studentsTableFilter ? this.props.studentsTableFilter : []}
              defaultFilterMethod={(filter, row) =>
                String(row[filter.id]) === filter.value
              }
              columns={[
                {
                  Header: "First name",
                  accessor: "first_name",
                  sortable: false,
                  filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["first_name"] }),
                  filterAll: true,
                  style: { textAlign: "center" },
                },
                {
                  Header: "Last name",
                  accessor: "last_name",
                  sortable: false,
                  filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["last_name"] }),
                  filterAll: true,
                  style: { textAlign: "center" },
                },
                {
                  Header: "Email",
                  accessor: "email",
                  sortable: false,
                  filterAll: true,
                  filterable: true,
                  filterMethod: (filter, rows) =>
                  matchSorter(rows, filter.value, { keys: ["email"] }),
                  Cell: (props) => {
                    return <span>{props.row.email}</span>;
                  },
                  style: { 
                    textAlign: "center",
                  },
                },
                {
                  Header: "Phone number",
                  accessor: "phone",
                  sortable: false,
                  filterAll: true,
                  filterable: true,
                  filterMethod: (filter, rows) =>
                  matchSorter(rows, filter.value, { keys: ["phone"] }),
                  Cell: (props) => {
                    return <span>{props.row.phone ? props.row.phone : '-'}</span>;
                  },
                  style: { 
                    textAlign: "center",
                  },
                },
                {
                  Header: "Type",
                  accessor: "type",
                  sortable: false,
                  filterAll: true,
                  filterable: true,
                  filterMethod: (filter, rows) =>
                  matchSorter(rows, filter.value, { keys: ["phone"] }),
                  Cell: (props) => {
                    return <span>{props.row.type}</span>;
                  },
                  style: { 
                    textAlign: "center",
                  },
                },
                {
                  Header: "",
                  accessor: "_id",
                  sortable: false,
                  filterable: false,
                  Cell: (row) => (
                    <span>
                        <button onClick={() => this.openEditUserHandler(row.original)}  className="btn-round btn-main-orange border-0 cursor-pointer px-3 py-1">
                          Edit
                        </button>
                    </span>
                  ),
                  style: { textAlign: "center" },
                },
              ]}
              ref={this.table}
              data={data} // should default to []
              pages={pages} // should default to -1 (which means we don't know how many pages we have)
              loading={isLoading}
              manual // informs React Table that you'll be handling sorting and pagination server-side
              onFetchData={this.getStudentsList}
              defaultPageSize={25}
              showPagination={true}
              className="-striped -highlight"
            />
          </div>
        </div>
        { isEditModalOpen ? 
          <EditUsersModal 
            first_name={selectedUser.first_name}
            last_name={selectedUser.last_name}
            email={selectedUser.email}
            phone={selectedUser.phone}
            _id={selectedUser._id}
            onCloseModal={this.closeEditUserHandler}
          /> 
        : null }
        { isAddModalOpen ? 
          <AddUsersModal 
            onCloseModal={this.closeAddUserHandler}
          /> 
        : null }

      </div>
    );
  };
};

const mapStateToProps = state => {
  return {
    auth: state.auth,
    modal: state.modal,
    tutorsTableFilter: state.tableFilter.tutors,
  }
}

export default connect(mapStateToProps, { userGetUsersList, setUsersTableFilter })(UsersTable);