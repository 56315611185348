import { isArray } from 'lodash';
import Validator from 'validator';

export function validateInputCategoryRule(data) {
	const isEmpty = (value) =>
		value === undefined ||
		value === null ||
		value === '-' ||
		(typeof value === 'object' && Object.keys(value).length === 0) ||
		(typeof value === 'string' && value.trim().length === 0);

	let errors = {};

	if (isEmpty(data.name)) {
		errors.name = 'Name is required';
	}

	if (isEmpty(data.label)) {
		errors.label = 'Label is required';
	}

	return {
		errors,
		isValid: isEmpty(errors),
	};
}

export function validateEditCategoryRule(data) {
	const isEmpty = (value) =>
		value === undefined ||
		value === null ||
		value === '-' ||
		(typeof value === 'object' && Object.keys(value).length === 0) ||
		(typeof value === 'string' && value.trim().length === 0);

	let errors = {};

	if (isEmpty(data.name)) {
		errors.name = 'Name is required';
	}

	if (isEmpty(data.label)) {
		errors.label = 'Label is required';
	}

	return {
		errors,
		isValid: isEmpty(errors),
	};
}
