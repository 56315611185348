import React, { Component } from 'react';
import MDSpinner from 'react-md-spinner';

import Notification from '../../common/Notification';
import TextAreaGroup from '../../common/TextAreaGroup';
import TextFieldGroup from "../../common/TextFieldGroup";
import ReactSelectFieldGroup from '../../common/ReactSelectFieldGroup';

import { connect } from 'react-redux';
import { validateEditUser } from '../../common/validations/users';
import { userEditUser, userDeleteUser } from '../../../actions/usersActions';

class EditUsersModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      notification: {},
      errors: { },
      first_name: this.props.first_name,
      last_name:  this.props.last_name,
      phone: this.props.phone,
      email: this.props.email,
      _id: this.props._id,
      is_active: true
    };
  };


  
  onChangeInput = e => {
    this.setState({ 
      [e.target.name] : e.target.value,
      errors: {
        ...this.state.errors,
        [e.target.name] : null
      }
    });
  };



  isValid = () => {
    const { errors, isValid } = validateEditUser(this.state);
    if(!isValid){
      this.setState({ errors });
    }
    return isValid;
  };

  onSubmitDeleteUser = e => {
    e.preventDefault();
    this.setState({ isLoading: true, notification: {}, errors: {}});
    this.props.userDeleteUser({id:this.state._id}).then(res => {
      if(res.status !== 200) {
        this.setState({ 
          isLoading: false,
          notification: {
            isError: true,
            text: res.message,
          }
        });
      } else {
        this.setState({ isLoading: false })
        this.props.onCloseModal();
      }
    });
  };

  onSubmitEditUser = e => {
    e.preventDefault();
    this.setState({ isLoading: true, notification: {}, errors: {}});
    
    const { 
      first_name,
      last_name,
      email,
      phone,
      is_active,
      _id
    } = this.state;
    
    const dataToSubmit = {
      first_name,
      last_name,
      email,
      phone,
      is_active,
      _id
    };
    if(this.isValid()) {
      this.props.userEditUser(dataToSubmit).then(res => {
        if(res.status !== 200) {
          this.setState({ 
            isLoading: false,
            notification: {
              isError: true,
              text: res.message,
            }
          });
        } else {
          this.setState({ isLoading: false })
          this.props.onCloseModal();
        }
      });
    } else {
      this.setState({
        isLoading: false,
        notification: {
          isError: true,
          text: "Please complete the form above"
        }
      })
    }
  };

  render() {
    const { 
      first_name,
      last_name,
      email,
      phone,
      isLoading,
      errors,
      notification,
    } = this.state;
    console.log(this.state);
    return (
      <div>
        <div
          className="modal modal-center fade show"
          id="modal-center"
          style={{ display: "block" }}
        >
          <div className="modal-dialog">
            <div className="modal-content" style={{ width: "692px"}}>
              <div className="modal-header">
                <h4 className="modal-title text-center font-weight-bold">Edit User</h4>
                <button onClick={this.props.onCloseModal} type="button" className="close" data-dismiss="modal">
                  <span aria-hidden="true">
                    <i className="ion-close-round fs-16 cursor-pointer"></i>
                  </span>
                </button>
              </div>
              <div className='modal-body package-modal m-0 py-30 px-40'>
                <TextFieldGroup
                  field="first_name"
                  label="First name"
                  value={first_name}
                  placeholder="First name"
                  onChange={this.onChangeInput}
                  disabled={isLoading}
                  error={errors.first_name}
                />
                <TextFieldGroup
                  field="last_name"
                  label="Last Name"
                  value={last_name}
                  placeholder="Last name"
                  onChange={this.onChangeInput}
                  disabled={isLoading}
                  error={errors.last_name}
                />
                <TextFieldGroup
                  field="email"
                  label="Email"
                  value={email}
                  placeholder="Type something"
                  onChange={this.onChangeInput}
                  disabled
                  error={errors.email}
                />
               <TextFieldGroup
                  type="number"
                  field="phone"
                  label="Phone number"
                  value={phone}
                  placeholder="Type something"
                  onChange={this.onChangeInput}
                  disabled={isLoading}
                  error={errors.phone}
                />
                <Notification notification={notification} />
              </div>
              <div className='modal-footer p-20'>
                { isLoading ? 
                  <div className='d-flex align-items-center justify-content-end'>
                    <MDSpinner />
                  </div>
                  : <div className='d-flex align-items-center justify-content-end'>
                    <button onClick={this.onSubmitEditUser} className='btn btn-main-orange btn-round letter-spacing-0 mr-2'>Save</button>
                    <button onClick={this.props.onCloseModal} className='btn border-orange btn-round letter-spacing-0 mr-2'>Cancel</button>
                    <button onClick={this.onSubmitDeleteUser} className='btn btn-main-red btn-round letter-spacing-0 mr-2'>Delete</button>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
        <div className="modal-backdrop fade show"></div>
      </div>
    );
  };
};


export default connect(null, { userEditUser,userDeleteUser })(EditUsersModal);
