import axios from 'axios';

// Set config defaults url
const { NODE_ENV: ENV } = process.env;

export const API = ENV === 'production' ? axios.create({
  baseURL: 'https://ideaal-server.whellostudio.id/api'
  // baseURL: ' https://Ideaal Catering-booking.whellostudio.id/api/'
}) : axios.create({
  // baseURL: ' https://Ideaal Catering-booking.whellostudio.id/api/'
  // baseURL: 'https://ideaal-server.whellostudio.id/api/'
  baseURL: 'http://localhost:8001/'
});

export const DOMAIN = ENV === 'production' ? 
  // 'https://Ideaal Catering.whellostudio.id'
  'https://https://ideaal-server.whellostudio.id/'
  : 'http://localhost:3000';
  // : 'https://https://ideaal-server.whellostudio.id/';


export const SERVER_DOMAIN = ENV === 'production' ? 
  // 'https://Ideaal Catering.whellostudio.id'
  // 'https://https://ideaal-server.whellostudio.id/api'
  'http://localhost:8001'
  : 'https://https://ideaal-server.whellostudio.id/api';
  //pass: z1U5XEAYZy0ArByprk4