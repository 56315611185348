import Validator from 'validator';

export function validateInputUser(data) {
    const isEmpty = value =>
    value === undefined ||
    value === null ||
    value === "-" ||
    (typeof value === 'object' && Object.keys(value).length === 0) ||
    (typeof value === 'string' && value.trim().length === 0);
      
      let errors = {};

    if(isEmpty(data.first_name)) {
      errors.first_name = "First name is required";
    } 

    if(isEmpty(data.last_name)) {
      errors.last_name = "Last name is required";
    } 
    
    if(isEmpty(data.type)) {
      errors.type = "Please select type";
    } 

    if (!isEmpty(data.email)) {
      if (!Validator.isEmail(data.email)) {
        errors.email = 'Email not valid';
      }
    } else {
      errors.email = 'Please input your email';
    }

    if(isEmpty(data.phone)) {
      errors.phone = "Phone is required";
    } else {
      if(isNaN(data.phone)) {
        errors.phone = "Please insert number";
      };
    };

  	return {
  		errors,
  		isValid: isEmpty(errors)
  	}
};

export function validateEditUser(data) {
    const isEmpty = value =>
    value === undefined ||
    value === null ||
    value === "-" ||
    (typeof value === 'object' && Object.keys(value).length === 0) ||
    (typeof value === 'string' && value.trim().length === 0);
      
      let errors = {};

    if(isEmpty(data.first_name)) {
      errors.first_name = "First name is required";
    } 

    if(isEmpty(data.last_name)) {
      errors.last_name = "Last name is required";
    } 

    if (!isEmpty(data.email)) {
      if (!Validator.isEmail(data.email)) {
        errors.email = 'Email not valid';
      }
    } else {
      errors.email = 'Please input your email';
    }

    if(isEmpty(data.phone)) {
      errors.phone = "Phone is required";
    } else {
      if(isNaN(data.phone)) {
        errors.phone = "Please insert number";
      };
    };

    return {
      errors,
      isValid: isEmpty(errors)
    }
};

