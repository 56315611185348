import React from 'react';
import { Switch } from 'react-router-dom';
import PrivateRoute from '../../privateRoute';
import Layout from '../layout/Layout';
import RulesTable from './views/rulesTable';


class RulesPage extends React.Component {
	render() {
		const { history, match } = this.props;
		return(
			<Layout history={history}>
                <main className="main-container">
                	<div className="main-content">
                        <Switch>
							<PrivateRoute path={`${match.path}`} component={RulesTable}/> 
						</Switch>
                	</div>
                </main>
             </Layout>
		);
	}
}

export default RulesPage;
