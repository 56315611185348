import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

class LeftPane extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: this.props.auth.user,
    };
  }
  render() {
    const { pathname } = this.props.history.location;
    const { user } = this.state;
    return (
      <aside className="sidebar sidebar-expand-lg sidebar-iconic sidebar-light sidebar-color-primary">
        <header className="sidebar-header bg-white">
          <img src="/assets/img/logo.png" alt="logo icon" />
        </header>
        <nav className="sidebar-navigation" style={{overflow: "auto"}}>
          <ul className="menu menu-sm menu-bordery">
            <li className={pathname === '/dashboard' ? "menu-item active" : "menu-item"}>
              <Link className="menu-link" to="/dashboard">
                <img alt="" className="w-25px" src="/assets/img/icon/dashboard.svg" />
                <span className="title">Dashboard</span>
              </Link>
            </li>
            <li className={pathname === '/rules' ? "menu-item active" : "menu-item"}>
              <Link className="menu-link" to="/rules">
                <div className='w-25px'>
                  <img alt="" className="" src="/assets/img/icon/checklist.svg" />
                </div>
                <span className="title">Rules</span>
              </Link>
            </li>
            <li className={pathname === '/category-rules' ? "menu-item active" : "menu-item"}>
              <Link className="menu-link" to="/category-rules">
                <div className='w-25px'>
                  <img alt="" className="" src="/assets/img/icon/checklist.svg" />
                </div>
                <span className="title">Category Rules</span>
              </Link>
            </li>
            { user.type === "admin" ?
            <li className={pathname === '/users' ? "menu-item active" : "menu-item"}>
              <Link className="menu-link" to="/users">
                <img alt="" className="" src="/assets/img/icon/users.svg" />
                <span className="title">Users</span>
              </Link>
            </li>
            : null
            }
            <li className={pathname === '/settings' ? "menu-item active" : "menu-item"}>
              <Link className="menu-link" to="/settings">
                <img alt="" className='w-25 manage-page-icon' src="/assets/img/icon/settings.svg" />
                <span className="title">Setting</span>
              </Link>
            </li>
          </ul>
        </nav>
      </aside>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}
export default connect(mapStateToProps)(LeftPane);
