import React from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

const TextFieldGroupLogin = ({
	field,
	defaultValue,
	value,
	id,
	label,
	error,
	type,
	onChange,
	disabled,
	icon,
	placeholder,
	nonBoldLabel,
	labelAddStyle,
	readOnly,
	alreadyHaveAccountButton,
	loginLink,
}) => {
	return (
		<div
			className={classnames(
				'form-group',
				{ 'has-error has-danger': error },
				{ 'do-float': disabled }
			)}>
			{alreadyHaveAccountButton ? (
				<div className='d-flex align-items-center justify-content-between'>
					<label
						style={labelAddStyle}
						className={
							nonBoldLabel
								? 'col-form-label letter-spacing-0 fs-15 pl-12'
								: 'col-form-label font-weight-bold letter-spacing-0 fs-15 pl-12'
						}>
						{label}
					</label>
					<Link
						to={loginLink}
						style={{ color: '#999999' }}
						className='col-form-label letter-spacing-0 fs-13 pl-12'>
						Already have an account ?
					</Link>
				</div>
			) : (
				<label
					style={labelAddStyle}
					className={
						nonBoldLabel
							? 'col-form-label letter-spacing-0 fs-15 pl-12'
							: 'col-form-label font-weight-bold letter-spacing-0 fs-15 pl-12'
					}>
					{label}
				</label>
			)}
			<div className=''>
				<input
					defaultValue={defaultValue}
					value={value}
					onChange={onChange}
					type={type}
					name={field}
					className='form-control px-20 py-2'
					style={{ borderRadius: '25px', height: '42px!important' }}
					disabled={disabled}
					id={id}
					readOnly={readOnly ? true : false}
					placeholder={placeholder}
				/>
				{error && <div className='form-control-feedback'>{error}</div>}
				{/* {maxWordsReached && <p className="fs-12 font-italic">You exceeded the maximum number of words</p> } */}
			</div>
			{icon && <div className={icon}></div>}
		</div>
	);
};

export default TextFieldGroupLogin;
