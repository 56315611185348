import { isArray } from 'lodash';
import Validator from 'validator';

export function validateInputRule(data) {
	const isEmpty = (value) =>
		value === undefined ||
		value === null ||
		value === '-' ||
		(typeof value === 'object' && Object.keys(value).length === 0) ||
		(typeof value === 'string' && value.trim().length === 0);

	let errors = {};

	if (isEmpty(data.name)) {
		errors.name = 'Name is required';
	}

	if (isEmpty(data.category)) {
		errors.category = 'Category is required';
	}

	if (isEmpty(data.type)) {
		errors.type = 'Type is required';
	}

	const dataType = data.type || '';

	if (
		dataType === 'date' ||
		dataType === 'date_zipcodes' ||
		dataType === 'people'
	) {
		if (isEmpty(data.date_type)) {
			errors.date_type = 'Date type is required';
		}

		if (isEmpty(data.date_start)) {
			errors.date_start = 'Date start not valid';
		}

		if (isEmpty(data.date_end)) {
			errors.date_end = 'Date end not valid';
		}
	}

	if (dataType === 'date_zipcodes' || dataType === 'people') {
		if (isEmpty(data.zipcode_type)) {
			errors.zipcode_type = 'Zipcode type not valid';
		}

		const dataZipcodeType = data.zipcode_type;

		if (dataZipcodeType === 'multiple_range') {
			const dataZipcodes = data.zipcodes;

			if (!isArray(dataZipcodes)) {
				errors.zipcodes =
					'Zipcodes is not multiple value, fill all field.';
			} else {
				errors.zipcodes = [];

				dataZipcodes.map((item, index) => {
					if (
						/\d{4}-\d{4}/.test(item) === false ||
						item.length !== 9
					) {
						errors.zipcodes[index] =
							'Zipcodes not valid for type range.';
					}
				});

				if (errors.zipcodes.length === 0) {
					delete errors.zipcodes;
				}
			}
		} else if (isEmpty(data.zipcodes)) {
			errors.zipcodes = 'Zipcodes is empty';
		} else {
			const dataZipcodes = data.zipcodes;

			if (
				dataZipcodeType === 'range' &&
				/\d{4}-\d{4}/.test(dataZipcodes) === false
			) {
				errors.zipcodes = 'Zipcodes not valid for type range.';
			} else if (
				dataZipcodeType === 'specific' &&
				(/\d{4}/.test(dataZipcodes) === false ||
					dataZipcodes.length !== 4)
			) {
				errors.zipcodes = 'Zipcodes not valid for type specific.';
			} else if (
				dataZipcodeType === 'multiple' &&
				/\d{4}\,\d{4}/g.test(dataZipcodes) === false
			) {
				errors.zipcodes = 'Zipcodes not valid for type multiple.';
			}
		}
	}

	if (dataType === 'people') {
		if (isEmpty(data.numberOfPerson)) {
			errors.numberOfPerson = 'Number Of Person not valid';
		}
	}

	if (isEmpty(data.customer_message)) {
		errors.customer_message = 'Customer message not valid';
	}

	return {
		errors,
		isValid: isEmpty(errors),
	};
}

export function validateEditRule(data) {
	const isEmpty = (value) =>
		value === undefined ||
		value === null ||
		value === '-' ||
		(typeof value === 'object' && Object.keys(value).length === 0) ||
		(typeof value === 'string' && value.trim().length === 0);

	let errors = {};

	if (isEmpty(data.name)) {
		errors.name = 'Name is required';
	}

	if (isEmpty(data.category)) {
		errors.category = 'Category is required';
	}

	if (isEmpty(data.type)) {
		errors.type = 'Type is required';
	}

	const dataType = data.type || '';

	if (
		dataType === 'date' ||
		dataType === 'date_zipcodes' ||
		dataType === 'people'
	) {
		if (isEmpty(data.date_type)) {
			errors.date_type = 'Date type is required';
		}

		if (isEmpty(data.date_start)) {
			errors.date_start = 'Date start not valid';
		}

		if (isEmpty(data.date_end)) {
			errors.date_end = 'Date end not valid';
		}
	}

	if (dataType === 'date_zipcodes' || dataType === 'people') {
		if (isEmpty(data.zipcode_type)) {
			errors.zipcode_type = 'Zipcode type not valid';
		}

		const dataZipcodeType = data.zipcode_type;

		if (dataZipcodeType === 'multiple_range') {
			const dataZipcodes = data.zipcodes;

			if (!isArray(dataZipcodes)) {
				errors.zipcodes =
					'Zipcodes is not multiple value, fill all field.';
			} else {
				errors.zipcodes = [];

				dataZipcodes.map((item, index) => {
					if (
						/\d{4}-\d{4}/.test(item) === false ||
						item.length !== 9
					) {
						errors.zipcodes[index] =
							'Zipcodes not valid for type range.';
					}
				});

				if (errors.zipcodes.length === 0) {
					delete errors.zipcodes;
				}
			}
		} else if (isEmpty(data.zipcodes)) {
			errors.zipcodes = 'Zipcodes is empty';
		} else {
			const dataZipcodes = data.zipcodes;

			if (
				dataZipcodeType === 'range' &&
				(/\d{4}-\d{4}/.test(dataZipcodes) === false ||
					dataZipcodes.length !== 9)
			) {
				errors.zipcodes = 'Zipcodes not valid for type range.';
			} else if (
				dataZipcodeType === 'specific' &&
				(/\d{4}/.test(dataZipcodes) === false ||
					dataZipcodes.length !== 4)
			) {
				errors.zipcodes = 'Zipcodes not valid for type specific.';
			} else if (
				dataZipcodeType === 'multiple' &&
				/\d{4}\,\d{4}/g.test(dataZipcodes) === false
			) {
				errors.zipcodes = 'Zipcodes not valid for type multiple.';
			}
		}
	}

	if (dataType === 'people') {
		if (isEmpty(data.numberOfPerson)) {
			errors.numberOfPerson = 'Number Of Person not valid';
		}

		if (isEmpty(data.numberOfPersonAllPeriod)) {
			errors.numberOfPersonAllPeriod =
				'Number Of Person Rest of Period not valid';
		}
	}

	if (isEmpty(data.customer_message)) {
		errors.customer_message = 'Customer message not valid';
	}

	return {
		errors,
		isValid: isEmpty(errors),
	};
}
