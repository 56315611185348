import React from "react";
import { Route, Switch } from "react-router-dom";
import Main from "./components/Main";

import loginPage from "./components/login/loginPage";
import forgetPasswordPage from "./components/forget-password/forgetPasswordPage";
import resetPasswordPage from "./components/reset-password/resetPasswordPage";
import accountActivationPage from "./components/account-activation/accountActivationPage";

import dashboardPage from "./components/dashboard/dashboardPage";
import UsersPage from "./components/users/usersPage";
import RulesPage from "./components/rules/rulesPage";
import CategoryRulesPage from "./components/category-rules/categoryRulesPage";

import PrivateRoute from "./privateRoute";
import SettingsPage from "./components/settings/settingsPage";

import AdminRoute from './adminRoute';

class App extends React.Component {
  render() {
    return (
      <div>
        <Switch>
          <Route exact path="/login" component={loginPage} />
          <Route exact path="/forget-password" component={forgetPasswordPage} />
          <Route exact path="/reset-password" component={resetPasswordPage} />
          <Route exact path="/activate-account" component={accountActivationPage} />
          <PrivateRoute path="/dashboard" component={dashboardPage} />
          <PrivateRoute path="/settings" component={SettingsPage} />
          <PrivateRoute path="/rules" component={RulesPage} />
          <PrivateRoute path="/category-rules" component={CategoryRulesPage} />
          <AdminRoute path="/users" component={UsersPage} />
          <Route path="/" component={Main} />
        </Switch>
      </div>
    );
  }
}

export default App;
