import React, { Component } from 'react';

import TextFieldGroup from '../../common/TextFieldGroup';
import Notification from '../../common/Notification';
import LoadingGift from '../../common/LoadingGift';

import { connect } from 'react-redux';
import { userLogout } from '../../../actions/authActions';
import {
	userEditAccount,
	userChangePassword,
} from '../../../actions/adminActions';
import {
	validateInputAddAccount,
	validateChangePassword,
} from '../../common/validations/admin';

class Settings extends Component {
	constructor(props) {
		super(props);
		this.state = {
			first_name: this.props.auth.user.first_name,
			last_name: this.props.auth.user.last_name,
			email: this.props.auth.user.email,
			phone: this.props.auth.user.phone ? this.props.auth.user.phone : '',
			password: '',
			new_password: '',
			repeat_password: '',
			errors: {},
			notification: {},
			notificationAuth: {},
			isLoading: false,
			isLoadingAuth: false,
		};
	}

	componentDidMount() {
		const currentUser = JSON.parse(localStorage.getItem('user'));

		this.setState({
			first_name: currentUser.first_name,
			last_name: currentUser.last_name,
			email: currentUser.email,
			phone: currentUser.phone,
		});
	}

	isValid = () => {
		const { errors, isValid } = validateInputAddAccount(this.state);
		if (!isValid) {
			this.setState({ errors });
		}
		return isValid;
	};

	isValidPassword = () => {
		const { errors, isValid } = validateChangePassword(this.state);
		if (!isValid) {
			this.setState({ errors });
		}
		return isValid;
	};

	onEditSave = () => {
		this.setState({
			isLoading: true,
			notification: {},
			notificationAuth: {},
		});
		const { email, first_name, last_name, phone } = this.state;
		const dataToSubmit = { email, first_name, last_name, phone };
		if (this.isValid()) {
			this.props
				.userEditAccount(dataToSubmit)
				.then((res) => {
					if (res.status === 200) {
						this.setState({
							isLoading: false,
							notification: {
								// text: 'Changes has been saved successfully. Please login again.',
								text: 'Changes has been saved successfully.',
								isError: false,
							},
						});

						localStorage.setItem(
							'user',
							JSON.stringify({
								...JSON.parse(localStorage.getItem('user')),
								...dataToSubmit,
							})
						);

						setTimeout(() => {
							this.setState({
								notification: {},
							});
							// this.props.userLogout();
						}, 3000);
					} else {
						console.log(res);
						this.setState({
							isLoading: false,
							notification: {
								text: 'Some features has been updated, please logout and login again',
								isError: true,
							},
						});
					}
				})
				.catch((err) => {
					console.log(err);
					this.setState({
						isLoading: false,
						notification: {
							text: 'Internal server error. Please try again later.',
							isError: true,
						},
					});
				});
		} else {
			this.setState({
				isLoading: false,
				notification: {
					text: 'Please complete the form above.',
					isError: true,
				},
			});
		}
	};

	onChangePasswordSave = () => {
		this.setState({
			isLoadingAuth: true,
			notification: {},
			notificationAuth: {},
		});
		const { email, new_password, password } = this.state;
		const dataToSubmit = { email, new_password, password };
		if (this.isValidPassword()) {
			this.props
				.userChangePassword(dataToSubmit)
				.then((res) => {
					if (res.status === 200) {
						this.setState({
							isLoadingAuth: false,
							notificationAuth: {
								text: 'Password has been saved successfully',
								isError: false,
							},
							password: '',
							new_password: '',
							repeat_password: '',
						});
						setTimeout(() => {
							this.setState({
								notificationAuth: {},
							});
						}, 3000);
					} else if (res.status === 401) {
						this.setState({
							isLoadingAuth: false,
							notificationAuth: {
								text: 'Password is incorrect. Please insert your current password.',
								isError: true,
							},
							errors: {
								password: 'Password is incorrect',
							},
						});
					} else {
						this.setState({
							isLoadingAuth: false,
							notificationAuth: {
								text: 'Internal server error, please try again later',
								isError: true,
							},
						});
					}
				})
				.catch((err) => {
					console.log(err);
					this.setState({
						isLoadingAuth: false,
						notificationAuth: {
							text: 'Some features has been updated, please logout and login again',
							isError: true,
						},
					});
				});
		} else {
			this.setState({
				isLoadingAuth: false,
				notificationAuth: {
					text: 'Please complete the form above',
					isError: true,
				},
			});
		}
	};

	onChange = (e) => {
		this.setState({
			errors: {
				...this.state.errors,
				[e.target.name]: '',
			},
			[e.target.name]: e.target.value,
		});
	};

	render() {
		const {
			first_name,
			last_name,
			email,
			phone,
			password,
			new_password,
			repeat_password,
			errors,
			isLoading,
			isLoadingAuth,
			notification,
			notificationAuth,
		} = this.state;

		return (
			<div className='text-center'>
				<div className='card'>
					<div className='card-header justify-content-center'>
						<h3 className='mb-0'>Account Settings</h3>
					</div>
					<div className='card-body justify-content-center p-30'>
						<div className='m-auto half-width-xl'>
							<div className='mb-30'>
								<TextFieldGroup
									field='email'
									value={email}
									id='email'
									label='Email'
									error={errors.email}
									onChange={this.onChange}
									disabled={true}
								/>
							</div>
							<div className='mb-30'>
								<TextFieldGroup
									field='first_name'
									value={first_name}
									id='first_name'
									label='First name'
									error={errors.first_name}
									onChange={this.onChange}
									disabled={isLoading}
								/>
							</div>
							<div className='mb-30'>
								<TextFieldGroup
									field='last_name'
									value={last_name}
									id='last_name'
									label='Last name'
									error={errors.last_name}
									onChange={this.onChange}
									disabled={isLoading}
								/>
							</div>
							<div className='mb-30'>
								<TextFieldGroup
									field='phone'
									value={phone}
									id='phone'
									label='Phone number'
									error={errors.phone}
									onChange={this.onChange}
									disabled={isLoading}
								/>
							</div>
						</div>
						<Notification notification={notification} />
					</div>
					<div className='card-footer justify-content-center'>
						{isLoading ? (
							<div className='col-md-12 text-center'>
								<LoadingGift />
							</div>
						) : (
							<button
								onClick={this.onEditSave}
								className='btn btn-main-orange btn-round'>
								Save changes
							</button>
						)}
					</div>
				</div>
				<div className='card'>
					<div className='card-header justify-content-center'>
						<h3 className='mb-0'>Change Password</h3>
					</div>
					<div className='card-body justify-content-center p-30'>
						<div className='m-auto half-width-xl'>
							<div className='mb-30'>
								<TextFieldGroup
									field='password'
									value={password}
									type='password'
									id='password'
									label='Old password'
									error={errors.password}
									onChange={this.onChange}
									placeholder='Type your old password'
									disabled={isLoadingAuth}
								/>
							</div>
							<div className='mb-30'>
								<TextFieldGroup
									field='new_password'
									value={new_password}
									type='password'
									id='new_password'
									label='New password'
									error={errors.new_password}
									onChange={this.onChange}
									placeholder='Type your new password'
									disabled={isLoadingAuth}
								/>
							</div>
							<div className='mb-30'>
								<TextFieldGroup
									field='repeat_password'
									value={repeat_password}
									id='repeat_password'
									type='password'
									label='Confirm password'
									error={errors.repeat_password}
									onChange={this.onChange}
									placeholder='Repeat your new password'
									disabled={isLoadingAuth}
								/>
							</div>
						</div>
						<Notification notification={notificationAuth} />
					</div>
					<div className='card-footer justify-content-center'>
						{isLoadingAuth ? (
							<div className='col-md-12 text-center'>
								<LoadingGift />
							</div>
						) : (
							<button
								onClick={this.onChangePasswordSave}
								className='btn btn-main-orange btn-round'>
								Save changes
							</button>
						)}
					</div>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		auth: state.auth,
	};
}

export default connect(mapStateToProps, {
	userEditAccount,
	userChangePassword,
	userLogout,
})(Settings);
