import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ReactTable from 'react-table';
import { matchSorter } from 'match-sorter';

import Notification from '../../common/Notification';

import { connect } from 'react-redux';
import { ruleCategoryGetRulesList } from '../../../actions/rulesCategoryActions';
import { setRulesTableFilter } from '../../../actions/tableFilterActions';
import AddCategoryRulesModal from './addCategoryRulesModal';
import EditCategoryRulesModal from './editCategoryRulesModal';

class CategoryRulesPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: [],
			pages: 1,
			isLoading: false,
			selected: {},
			selectedRule: {},
			sync: false,
			notification: {},
			errors: {},
			isEditModalOpen: false,
			isAddModalOpen: false,
		};
		this.table = React.createRef();
	}

	shouldComponentUpdate = (nextProps, nextState) => {
		if (this.props.modal.sync) {
			if (nextProps.modal.sync !== this.props.modal.sync) {
				this.getRulesList(this.table.current.state);
			}
			return true;
		}
		return true;
	};

	componentWillMount = () => {
		window.scroll({
			top: 0,
			left: 0,
			behavior: 'smooth',
		});
	};

	componentWillUnmount = () => {
		this.props.setRulesTableFilter(this.state.table_filter);
	};

	openEditRuleHandler = (data) => {
		this.setState({
			isEditModalOpen: true,
			selectedRule: data,
		});
	};

	closeEditRuleHandler = () => {
		this.setState({
			isEditModalOpen: false,
			selectedRule: {},
		});
		this.getRulesList(this.table.current.state);
	};

	openAddUserHandler = () => {
		this.setState({
			isAddModalOpen: true,
		});
	};

	closeAddUserHandler = () => {
		this.setState({
			isAddModalOpen: false,
		});
		this.getRulesList(this.table.current.state);
	};

	getRulesList = (state, instance) => {
		this.setState({
			isLoading: true,
			table_filter: state.filtered,
			notification: {},
		});
		// const fromDate = moment(this.state.from_date).subtract("days", 1);
		// const toDate = moment(this.state.to_date).add("days", 1);
		this.props
			.ruleCategoryGetRulesList({
				pagination: {
					pageSize: state.pageSize,
					page: state.page,
				},
				sorted: state.sorted,
				filtered: state.filtered,
			})
			.then((response) => {
				if (response.status !== 200) {
					this.setState({
						isLoading: false,
						notification: {
							text: 'Some features has been updated, please logout and login again',
							isError: true,
						},
					});
				} else {
					this.setState({
						isLoading: false,
						data: response.message.data,
						loading: false,
						pages: response.message.totalPage,
						totalStudents: response.message.totalStudents,
					});
				}
			})
			.catch((error) => {
				this.setState({ isLoading: false });
			});
	};

	render() {
		const { match } = this.props;
		const {
			data,
			pages,
			isLoading,
			notification,
			selectedRule,
			isEditModalOpen,
			isAddModalOpen,
		} = this.state;

		return (
			<div>
				<div className='card mx-15'>
					<div className='card-header flex-column justify-content-center'>
						<h1 className='mb-0'>Category Rules</h1>
						<div
							role='button'
							id='muted-text-group'
							style={{ right: 34 }}
							onClick={() => this.openAddUserHandler()}
							className='position-absolute d-flex align-items-center justify-content-center cursor-pointer font-weight-bold btn-info btn-round py-10 px-20'>
							<i className='ion-plus-round cursor-pointer mr-2'></i>
							Add Category Rule
						</div>
					</div>
					<div className='main-view'>
						<Notification notification={notification} />
						<ReactTable
							filterable
							defaultFiltered={
								this.props.studentsTableFilter
									? this.props.studentsTableFilter
									: []
							}
							defaultFilterMethod={(filter, row) =>
								String(row[filter.id]) === filter.value
							}
							columns={[
								{
									Header: 'Slug',
									accessor: 'name',
									sortable: false,
									filterMethod: (filter, rows) =>
										matchSorter(rows, filter.value, {
											keys: ['name'],
										}),
									filterAll: true,
									Cell: (props) => props.row.name,
									style: { textAlign: 'center' },
								},
								{
									Header: 'Label',
									accessor: 'label',
									sortable: false,
									filterMethod: (filter, rows) =>
										matchSorter(rows, filter.value, {
											keys: ['label'],
										}),
									filterAll: true,
									Cell: (props) => props.row.label,
									style: { textAlign: 'center' },
								},
								{
									Header: '',
									accessor: '_id',
									sortable: false,
									filterable: false,
									Cell: (row) => (
										<span>
											<button
												onClick={() =>
													this.openEditRuleHandler(
														row.original
													)
												}
												className='btn-round btn-main-orange border-0 cursor-pointer px-3 py-1'>
												Edit
											</button>
										</span>
									),
									style: { textAlign: 'center' },
								},
							]}
							ref={this.table}
							data={data} // should default to []
							pages={pages} // should default to -1 (which means we don't know how many pages we have)
							loading={isLoading}
							manual // informs React Table that you'll be handling sorting and pagination server-side
							onFetchData={this.getRulesList}
							defaultPageSize={25}
							showPagination={true}
							className='-striped -highlight'
						/>
					</div>
				</div>
				{isEditModalOpen ? (
					<EditCategoryRulesModal
						name={selectedRule.name}
						label={selectedRule.label}
						_id={selectedRule._id}
						onCloseModal={this.closeEditRuleHandler}
					/>
				) : null}
				{isAddModalOpen ? (
					<AddCategoryRulesModal onCloseModal={this.closeAddUserHandler} />
				) : null}
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		auth: state.auth,
		modal: state.modal,
		tutorsTableFilter: state.tableFilter.tutors,
	};
};

export default connect(mapStateToProps, {
	ruleCategoryGetRulesList,
	setRulesTableFilter,
})(CategoryRulesPage);
