import React from "react";
import moment from 'moment';
import { DOMAIN } from "../../../utils/apiConnect";

class Footer extends React.Component {
  render() {
    return (
      <footer className="site-footer">
        <div className="row">
          <div className="col-md-6">
            <p className="text-center text-md-left">
              Copyright © {moment(new Date()).format('YYYY')}{" "}
              <a href={DOMAIN}>Ideaal Catering</a>. All rights
              reserved.
            </p>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
