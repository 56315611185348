import { API } from '../utils/apiConnect';

export function ruleCategoryGetRulesList(data) {
	return dispatch => {
		return API.post('/category-rules/list', data)
		.then( res => {
			return res.data;
		});
	}
}

export function ruleCategoryAddRule(data) {
	return dispatch => {
		return API.post('/category-rules/add', data)
		.then( res => {
			return res.data;
		});
	}
}

export function ruleCategoryEditRule(data) {
	return dispatch => {
		return API.post('/category-rules/edit', data)
		.then( res => {
			return res.data;
		});
	}
}

export function ruleCategoryGetSingleRule(data) {
	return dispatch => {
		return API.post('/category-rules/single', data)
		.then( res => {
			return res.data;
		});
	}
}

export function ruleCategoryDeleteRule(data) {
	return dispatch => {
		return API.post('/category-rules/delete', data)
		.then( res => {
			return res.data;
		});
	}
}
